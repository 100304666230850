import React from "react"
import { Menu } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#242424",
  },
})

export default function NavbarMenu({
  anchorEl,
  isOpen,
  handleClose,
  children,
}) {
  const classes = useStyles()
  return (
    <Menu
      classes={{ paper: classes.paper }}
      elevation={0}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {children}
    </Menu>
  )
}
