import React from "react"
import { List, ListItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

const useStyles = makeStyles({
  listItem: {
    height: 50,
  },
  navLink: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.87)",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
})

export default function JetList({ handleSidebarToggle }) {
  const classes = useStyles()

  return (
    <List disablePadding>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          to="/jet/getting-started"
          onClick={handleSidebarToggle}
        >
          Getting Started
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/jet/manual-jobs"
        >
          Manual Jobs
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/jet/email-notifications"
        >
          Email Notifications
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/jet/webhooks"
        >
          Webhook Notifications
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/jet/webhook-reference"
        >
          Jet Event
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/jet/api-documentation"
        >
          API Documentation
        </Link>
      </ListItem>
    </List>
  )
}
