/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

require("prismjs/themes/prism-tomorrow.css");

const collapsedClassName = 'collapsed-code-block';
const expandedClassName = 'expanded-code-block';
exports.onClientEntry = () => {
  window.showMore = (e, id) => {
    const button = e.target;
    const collapsable = document.getElementById(id);
    if (collapsable.className === collapsedClassName) {
      collapsable.className = expandedClassName;
      button.innerHTML = 'See Less -';
      return;
    }
    button.innerHTML = 'See More +';
    collapsable.className = collapsedClassName;
  }
}