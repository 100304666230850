import React from "react"
import { useState } from "react"
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { ListItem, Collapse, Divider } from "@material-ui/core"

const useStyles = makeStyles({
  listItem: {
    height: 50,
  },
  productName: {
    margin: 0,
    fontSize: "14px",
    color: "rgba(0,0,0,0.87)",
  },
  chevron: {
    marginLeft: "auto",
  },
  subDivider: {
    marginLeft: "1em",
  },
  listWrapper: {
    marginLeft: "1.25em",
  },
})

export default function Accordion({ productName, productList }) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  const handleAccordionToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <ListItem
        button
        onClick={handleAccordionToggle}
        className={classes.listItem}
      >
        <p className={classes.productName}>{productName}</p>
        {isOpen ? (
          <KeyboardArrowRight className={classes.chevron} />
        ) : (
          <KeyboardArrowDown className={classes.chevron} />
        )}
      </ListItem>
      {/* <ListItem> */}
      <Collapse in={isOpen} timeout="auto">
        <Divider className={classes.subDivider} />
        <div className={classes.listWrapper}>{productList}</div>
      </Collapse>
      {/* </ListItem> */}
    </>
  )
}
