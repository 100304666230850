import React from "react"
import { MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Menu from "../index"
import { Link } from "gatsby"

const useStyles = makeStyles({
  navLink: {
    color: "white",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontWeight: "200",
    "&:hover": {
      color: "white",
    },
  },
})

export default function MediaShuttleMenu({ isOpen, anchorEl, handleClose }) {
  const classes = useStyles()
  return (
    <Menu isOpen={isOpen} anchorEl={anchorEl} handleClose={handleClose}>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/media-shuttle/getting-started">
          Getting Started
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/media-shuttle/s2p-integration-guide"
        >
          System-to-Person Integration Guide
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link
          className={classes.navLink}
          to="/media-shuttle/working-with-webhooks"
        >
          System-to-Person Webhooks
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/media-shuttle/custom-contact">
          Custom Contact Forms
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <Link className={classes.navLink} to="/media-shuttle/api-documentation">
          API Documentation
        </Link>
      </MenuItem>
      <MenuItem onClick={handleClose}>
         <Link className={classes.navLink} to="/media-shuttle/media-shuttle-sdk">
             Media Shuttle SDK
         </Link>
      </MenuItem>
    </Menu>
  )
}
