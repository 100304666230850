import React from "react"
import Script from "react-load-script"
import { Input, InputAdornment } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useMediaQueries } from "../../hooks/useMediaQueries"
import { navigate } from "gatsby"

const useStyles = makeStyles({
  input: {
    fontSize: "14px",
    backgroundColor: "#5b5b5b",
    color: "white",
    minWidth: "200px",
    width: "100%",
    borderRadius: "2px",
    padding: "0px 5px",
  },
  inputWrapper: {
    marginLeft: "auto",
  },
  inputWrapperSm: {
    width: "100%",
  },
})

export default function DocSearch({ inputAdornment }) {
  const classes = useStyles()
  const smBreakpoint = useMediaQueries("sm")

  const enableSearch = () => {
    window.docsearch({
      apiKey: "d2fe26cfe3f0c4d6ceb5e02f2de23b73",
      indexName: "developer_signiant",
      inputSelector: "#algolia-doc-search",
      debug: true,
      handleSelected: (input, event, suggestion, datasetNumber, context) => {
        const regex = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/g
        let match = regex.exec(suggestion.url)
        if (match === null || match.length < 2) {
          return "/"
        }

        const path = match[1].replace(".html", "")
        navigate(path)
      },
    })
  }

  return (
    <>
      <Script
        url="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.js"
        onLoad={enableSearch}
      />
      <div
        className={
          !smBreakpoint ? classes.inputWrapper : classes.inputWrapperSm
        }
      >
        <Input
          className={classes.input}
          placeholder="Search Documentation"
          variant="outlined"
          id="algolia-doc-search"
          startAdornment={
            <InputAdornment position="start">{inputAdornment}</InputAdornment>
          }
        />
      </div>
    </>
  )
}
