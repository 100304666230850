import React from "react"
import * as styles from "./footer.module.css"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.aboutContainer}>
        <h2 className={styles.title}>About Signiant</h2>
        <p>
          Signiant’s intelligent file movement software helps the world’s top
          content creators and distributors ensure fast, secure delivery of
          large files over public and private networks. Built on Signiant’s
          patented technology, the company’s on-premises software and SaaS
          solutions move petabytes of high-value data every day between users,
          applications and systems with proven ease.
        </p>
      </div>
      <div className={styles.quickLinksContainer}>
        <h3 className={styles.title}>Quick Links</h3>
        <ul className={styles.quickLinks}>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://help.signiant.com"
            >
              Help
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.signiant.com"
            >
              Support
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.signiant.com/blog/"
            >
              Blog
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.signiant.com/resources/case-studies/"
            >
              Case Studies
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.signiant.com/technology/"
            >
              Core Technology
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
