import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import JetMenu from "../../Menu/JetMenu"
import MediaShuttleMenu from "../../Menu/MediaShuttleMenu"
import ManagerAgentsMenu from "../../Menu/ManagerAgentsMenu"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons"

const useStyles = makeStyles({
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexGrow: 1,
    height: "25px",
  },
  navLink: {
    color: "white",
    border: "none",
    fontWeight: 400,
    fontFamily: "inherit",
    fontSize: "14px",
    display: "flex",
    height: "100%",
    "align-items": "center",
    "justify-content": "center",
    padding: "0.5em",
    textTransform: "uppercase",

    "&:hover": {
      color: "white",
      textDecoration: "underline",
    },
  },
})

export default function NavBarLink() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [menu, setMenu] = useState("")
  const classes = useStyles()

  const handleClick = e => {
    const label = e.target.getAttribute("data-label")
    setMenu(label)

    switch (label) {
      case "Jet":
        setAnchorEl(document.getElementById("jet-nav"))
        break
      case "Media Shuttle":
        setAnchorEl(document.getElementById("media-shuttle-nav"))
        break
      case "Flight Deck":
        setAnchorEl(document.getElementById("manager-agents-nav"))
        break
      default:
        break
    }
  }

  const handleClose = e => {
    setMenu(false)
  }
  return (
    <div className={classes.linkContainer}>
      <Typography variant="body1" className={classes.navLink}>
        <Link className={classes.navLink} to="/">
          Home
        </Link>
      </Typography>
      <Typography variant="body1" className={classes.navLink}>
        <Link className={classes.navLink} to="/release-notes">
          Release Notes
        </Link>
      </Typography>
      <Typography
        variant="body1"
        id="jet-nav"
        className={classes.navLink}
        onClick={handleClick}
        data-label="Jet"
      >
        Jet
        {menu === "Jet" ? (
          <KeyboardArrowRight onClick={handleClick} data-label="Jet" />
        ) : (
          <KeyboardArrowDown onClick={handleClick} data-label="Jet" />
        )}
      </Typography>

      <JetMenu
        isOpen={menu === "Jet"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <Typography
        variant="body1"
        id="media-shuttle-nav"
        className={classes.navLink}
        onClick={handleClick}
        data-label="Media Shuttle"
      >
        Media Shuttle
        {menu === "Media Shuttle" ? (
          <KeyboardArrowRight
            onClick={handleClick}
            data-label="Media Shuttle"
          />
        ) : (
          <KeyboardArrowDown onClick={handleClick} data-label="Media Shuttle" />
        )}
      </Typography>
      <MediaShuttleMenu
        isOpen={menu === "Media Shuttle"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <Typography
        variant="body1"
        id="manager-agents-nav"
        className={classes.navLink}
        onClick={handleClick}
        data-label="Flight Deck"
      >
          Flight Deck
        {menu === "Flight Deck" ? (
          <KeyboardArrowRight
            onClick={handleClick}
            data-label="Flight Deck"
          />
        ) : (
          <KeyboardArrowDown
            onClick={handleClick}
            data-label="Flight Deck"
          />
        )}
      </Typography>
      <ManagerAgentsMenu
        isOpen={menu === "Flight Deck"}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  )
}
