import React from "react"
import { useState } from "react"
import { Menu as MenuIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import { Drawer, List, ListItem, Divider } from "@material-ui/core"
import { Link } from "gatsby"
import Accordion from "./accordion"
import JetList from "./jetList"
import MAList from "./maList"
import MSList from "./msList"

const useStyles = makeStyles({
  menu: {
    color: "white",
    margin: "0 0.5em",
    marginTop: "1.5em",
    alignSelf: "center",
  },
  drawerPaper: {
    width: 270,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    textAlign: "left",
    paddingTop: "4em",
  },
  listItem: {
    height: 50,
  },
  navLink: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.87)",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
})

export default function Sidebar() {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <MenuIcon className={classes.menu} onClick={handleToggle} />
      <Drawer
        anchor="left"
        open={isOpen}
        variant="temporary"
        onClose={handleToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Link className={classes.navLink} onClick={handleToggle} to="/">
              Home
            </Link>
          </ListItem>
          <Divider />
          <Accordion
            productName="Jet"
            productList={<JetList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <Accordion
            productName="Media Shuttle"
            productList={<MSList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <Accordion
            productName="Flight Deck"
            productList={<MAList handleSidebarToggle={handleToggle} />}
          />
          <Divider />
          <ListItem className={classes.listItem}>
            <Link
              className={classes.navLink}
              onClick={handleToggle}
              to="/release-notes"
            >
              Release Notes
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </div>
  )
}
