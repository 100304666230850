import React from "react"
import { List, ListItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

const useStyles = makeStyles({
  listItem: {
    height: 50,
  },
  navLink: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.87)",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
  },
})

export default function MAList({ handleSidebarToggle }) {
  const classes = useStyles()

  return (
    <List disablePadding>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/get-started-with-rest"
        >
          Getting Started
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/job-scheduling"
        >
          Configuring Scheduled Jobs
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/bandwidth-management"
        >
          Bandwidth Management
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/job-examples"
        >
          REST API Job Example
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/api-documentation"
        >
            Manager API Documentation
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/flight-deck-api-documentation"
        >
            Flight Deck API Documentation
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/soap-development-guide"
        >
          SOAP API Documentation
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={classes.navLink}
          onClick={handleSidebarToggle}
          to="/flight-deck/fims-development-guide"
        >
          FIMS API Documentation
        </Link>
      </ListItem>
    </List>
  )
}
