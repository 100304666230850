import React from "react"
import { useState } from "react"
import Logo from "../../images/logo.svg"
import * as styles from "./navbar.module.css"
import { Link } from "gatsby"
import { useMediaQueries } from "../../hooks/useMediaQueries"
import NavbarLinks from "./NavbarLinks"
import Sidebar from "../Sidebar"
import DocSearch from "../DocSearch"
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  searchIcon: {
    color: "white",
  },

  searchIconWrapper: {
    marginLeft: "auto",
    marginRight: "2em",
    marginBottom: "3px",
  },
})

export default function Navbar() {
  const classes = useStyles()
  const [searchOpen, setSearchOpen] = useState(false)
  const lgBreakpoint = useMediaQueries("lg")
  const smBreakpoint = useMediaQueries("sm")

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen)
  }

  const renderMobileSearch = () => {
    return !searchOpen ? (
      <div className={classes.searchIconWrapper}>
        <SearchIcon
          className={classes.searchIcon}
          onClick={handleSearchToggle}
        />
      </div>
    ) : (
      <DocSearch
        onClick={handleSearchToggle}
        inputAdornment={<CloseIcon onClick={handleSearchToggle} />}
      />
    )
  }

  // Rendering search for bigger screens
  const renderSearch = () => {
    //Reset the searchOpen state to be false
    if (searchOpen) {
      handleSearchToggle()
    }
    return (
      <DocSearch
        inputAdornment={<SearchIcon className={classes.searchIcon} />}
      />
    )
  }

  return (
    <nav className={styles.navbar}>
      {lgBreakpoint ? <Sidebar /> : null}
      {!searchOpen ? (
        <Link to="/">
          <img src={Logo} alt="logo i" className={styles.logo} />
        </Link>
      ) : null}

      {lgBreakpoint ? null : <NavbarLinks />}
      {smBreakpoint ? renderMobileSearch() : renderSearch()}
    </nav>
  )
}